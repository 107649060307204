import { ModalHeader, ModalHeaderProps } from '@wearemojo/ui-components';

import IntercomButton from './IntercomButton';

type Props = ModalHeaderProps & {
	helpType?: 'support';
	showSupportButton?: boolean;
} & (
		| { helpContext: string; showSupportButton?: boolean }
		| { helpContext?: string; showSupportButton: false }
	);

const ModalScreenHeader = ({
	helpType = 'support',
	helpContext,
	showSupportButton = true,
	...rest
}: Props) => {
	return (
		<ModalHeader
			{...rest}
			supportButton={
				helpContext && showSupportButton ? (
					<IntercomButton type={helpType} context={helpContext} />
				) : null
			}
		/>
	);
};

export default ModalScreenHeader;
