import { useEffect } from 'react';

import api from '../store/api';
import useCompleteActivity from '../store/api/hooks/useCompleteActivity';
import {
	resetLoggedOutEvents,
	selectLoggedOutEvents,
} from '../store/loggedOutEvents';
import { selectUserId } from '../store/session';
import useEndpointMutation from './queries/useEndpointMutation';
import useAppDispatch from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import useBackendUserIdentifier from './useBackendUserIdentifier';
import { useLocalization } from './useLocalization';

const useProcessLoggedOutEvents = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const events = useAppSelector(selectLoggedOutEvents);

	const userIdentifier = useBackendUserIdentifier();
	const { timezone } = useLocalization();

	const { recordActivityCompleted } = useCompleteActivity();
	const [upsertMemoryMutation] = useEndpointMutation(
		api.endpoints.upsertItoMemory.useMutation(),
	);

	useEffect(() => {
		if (!userId) return;
		if (!userIdentifier) return;

		events.forEach((event) => {
			if (event.action === 'recordActivityCompleted') {
				recordActivityCompleted(
					event.data.payload,
					event.data.analyticsPayload,
				);
			}
		});

		// if user changed their name multiple times, let's make sure we only care about the last one
		const upsertMemoryEvent = events.findLast(
			(event) => event.action === 'upsertMemory',
		);
		if (
			upsertMemoryEvent &&
			upsertMemoryEvent.action === 'upsertMemory' // make TS happy by forcing it to narrow the type
		) {
			upsertMemoryMutation({
				content: upsertMemoryEvent.data.payload.content,
				context: upsertMemoryEvent.data.payload.context,
				timezone,
				userIdentifier,
				runId: null,
			});
		}

		dispatch(resetLoggedOutEvents());
	}, [
		dispatch,
		events,
		recordActivityCompleted,
		timezone,
		upsertMemoryMutation,
		userId,
		userIdentifier,
	]);
};

export default useProcessLoggedOutEvents;
