import {
	CheckboxWithLabel,
	CMSContent,
	CookieConsentView,
	Stack,
} from '@wearemojo/ui-components';
import { Spacing } from '@wearemojo/ui-constants';
import { useState } from 'react';

import { AnalyticsEvent, AnalyticsPayload, TrackEventHook } from '../analytics';
import {
	MOJO_COOKIE_CONSENT_VERSION,
	setCookieConsent,
} from '../analytics/attribution';
import { FragmentType } from '../content/FragmentType';
import { useFragmentOutsideContext } from '../hooks/content/useFragment';
import ModalManager from '../ModalManager';

const CookieConsentModal = ({
	trackEvent,
}: {
	trackEvent: ReturnType<TrackEventHook>;
}) => {
	const { closeModal } = ModalManager.useModal();
	const [choices, setChoices] = useState({
		analytics: false,
		advertising: false,
	});

	const {
		title,
		content,
		acceptCta,
		rejectCta,
		manageCta,
		preferencesTitle,
		preferencesContent,
		preferencesAnalyticsTitle,
		preferencesAnalyticsContent,
		preferencesAdvertisingTitle,
		preferencesAdvertisingContent,
		preferencesAcceptCta,
		preferencesSaveCta,
	} = useFragmentOutsideContext(FragmentType.CookieConsent);

	const [manage, setManage] = useState(false);

	const onManagePress = () => {
		setManage(true);
	};

	const onAcceptPress = () => {
		const _choices = {
			analytics: true,
			advertising: true,
		};
		setCookieConsent(_choices);
		trackEvent(AnalyticsEvent.cookie_consent_updated, {
			choices: _choices,
			version: MOJO_COOKIE_CONSENT_VERSION,
		} as AnalyticsPayload[AnalyticsEvent.cookie_consent_updated]);
		closeModal();
	};

	const onRejectPress = () => {
		const _choices = {
			analytics: false,
			advertising: false,
		};
		setCookieConsent(_choices);
		trackEvent(AnalyticsEvent.cookie_consent_updated, {
			choices: _choices,
			version: MOJO_COOKIE_CONSENT_VERSION,
		} as AnalyticsPayload[AnalyticsEvent.cookie_consent_updated]);
		closeModal();
	};

	const onSavePress = () => {
		setCookieConsent(choices);
		trackEvent(AnalyticsEvent.cookie_consent_updated, {
			choices,
			version: MOJO_COOKIE_CONSENT_VERSION,
		} as AnalyticsPayload[AnalyticsEvent.cookie_consent_updated]);
		closeModal();
	};

	const onClosePress = () => {
		onRejectPress();
	};

	return (
		<CookieConsentView
			manage={manage}
			title={!manage ? title : preferencesTitle}
			acceptCta={!manage ? acceptCta : preferencesAcceptCta}
			onAcceptPress={onAcceptPress}
			rejectCta={rejectCta}
			onRejectPress={onRejectPress}
			saveCta={preferencesSaveCta}
			onSavePress={onSavePress}
			manageCta={manageCta}
			onManagePress={onManagePress}
			onClosePress={onClosePress}
		>
			<CMSContent value={!manage ? content : preferencesContent} />
			{manage ? (
				<Stack spacing={Spacing.regular}>
					<CheckboxWithLabel
						label={preferencesAnalyticsTitle}
						textProps={{ weight: 'bold' }}
						checked={choices.analytics}
						onChange={(checked) =>
							setChoices({ ...choices, analytics: checked })
						}
					/>
					<CMSContent value={preferencesAnalyticsContent} />
					<CheckboxWithLabel
						label={preferencesAdvertisingTitle}
						textProps={{ weight: 'bold' }}
						checked={choices.advertising}
						onChange={(checked) =>
							setChoices({ ...choices, advertising: checked })
						}
					/>
					<CMSContent value={preferencesAdvertisingContent} />
				</Stack>
			) : undefined}
		</CookieConsentView>
	);
};

export default CookieConsentModal;
