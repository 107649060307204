import { Assets } from '@wearemojo/ui-components';
import { ImageProps } from 'expo-image';

import api from '../store/api';
import usePollResponses from '../store/api/hooks/usePollResponses';
import useEndpointQuery from './queries/useEndpointQuery';

export const chooseTherapistPollId = 'poll_000000CygC80ibiNj7e3uFPfn0b7A';

/**
 * Useful hook to access user's poll answer to which AI therapist they choose
 */
const useChooseTherapistPollResponse = (): {
	therapistKey?: string;
	therapistName?: string;
	therapistImage?: ImageProps['source'];
	therapistChosen?: boolean;
} => {
	const { data: polls } = useEndpointQuery(
		api.endpoints.getPollContent.useQuery(),
	);
	const { data: pollResponses } = usePollResponses();

	const therapistNamePoll = polls?.find((x) => chooseTherapistPollId === x.id);
	const pollResponse = pollResponses
		? (pollResponses.find((r) => r.pollId === chooseTherapistPollId) ?? null)
		: undefined;

	if (!therapistNamePoll || pollResponse === undefined) {
		return {
			therapistKey: undefined,
			therapistName: undefined,
			therapistChosen: undefined,
			therapistImage: Assets.itoAvatar,
		};
	}

	const therapistKey = pollResponse?.optionKeys[0];
	const therapistName =
		therapistNamePoll.options.find((option) => option.key === therapistKey)
			?.text || 'Emily';

	const therapistChosen = !!therapistKey;

	const therapistImage =
		(therapistKey &&
			Assets.therapists?.[therapistKey as keyof typeof Assets.therapists]) ||
		Assets.itoAvatar;

	return {
		therapistKey,
		therapistName,
		therapistChosen,
		therapistImage,
	};
};

export default useChooseTherapistPollResponse;
