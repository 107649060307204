import { Document } from '@wearemojo/sanity-schema/schema/_types';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import type { FragmentTypes } from '../../content/FragmentType';
import { FragmentType } from '../../content/FragmentType';
import api from '../../store/api';
import { resolveSanityDocuments } from '../../utils/resolveSanityDocuments';
import useEndpointQuery from '../queries/useEndpointQuery';

type ContextProps = {
	data?: Document[];
	isLoading: boolean;
};

const FragmentContext = createContext<ContextProps>({ isLoading: false });

const useFragmentProviderContextValue = (): ContextProps => {
	const { data: expressionDocuments, isLoading: isLoadingExpressions } =
		useEndpointQuery(api.endpoints.getExpressionDocuments.useQuery());
	const { data: fragmentDocuments, isLoading: isLoadingFragments } =
		useEndpointQuery(api.endpoints.getFragmentDocuments.useQuery());

	const context = useMemo(
		() => ({
			data: expressionDocuments &&
				fragmentDocuments && [
					...resolveSanityDocuments([
						...expressionDocuments,
						...fragmentDocuments,
					]).values(),
				],
			isLoading: isLoadingExpressions || isLoadingFragments,
		}),
		[
			expressionDocuments,
			fragmentDocuments,
			isLoadingExpressions,
			isLoadingFragments,
		],
	);

	return context;
};

export const FragmentProvider = ({ children }: { children: ReactNode }) => {
	const context = useFragmentProviderContextValue();

	return (
		<FragmentContext.Provider value={context}>
			{children}
		</FragmentContext.Provider>
	);
};

export const useFragments = () => useContext(FragmentContext);

export const useFragment = <T extends FragmentType>(typeName: T) => {
	const documents = useFragments().data;
	if (!documents) {
		throw new Error('Fragments not loaded');
	}

	const fragment = documents.find(({ _type }) => _type === typeName);
	if (!fragment) {
		throw new Error(`Fragment missing: ${typeName}`);
	}

	return fragment as FragmentTypes[T];
};

/**
 * This variant of useFragment is only meant to be used where the FragmentProvider context is not available.
 * For example, inside ModalManager modal components.
 */
export const useFragmentOutsideContext = <T extends FragmentType>(
	typeName: T,
) => {
	const documents = useFragmentProviderContextValue().data;
	if (!documents) {
		throw new Error('Fragments not loaded');
	}

	const fragment = documents.find(({ _type }) => _type === typeName);
	if (!fragment) {
		throw new Error(`Fragment missing: ${typeName}`);
	}

	return fragment as FragmentTypes[T];
};
