import Cher from '@wearemojo/cher';
import { BackendError } from '@wearemojo/json-client';

import { requestUrlSanitizer } from '../utils/requests';

type strippedCher = Pick<Cher, 'code'> & {
	reasons?: strippedCher[];
	meta_error_name?: string;
	meta_error_message?: string;
	error_detail?: unknown;
	request_url?: string;
};

export const stripCher = (error: Cher): strippedCher => {
	const requestUrl =
		error instanceof BackendError && error.requestUrl != null
			? requestUrlSanitizer(error.requestUrl)
			: undefined;

	return {
		code: error.code,
		reasons: error.reasons?.map(stripCher),
		meta_error_name: (error.meta?.error as Error | undefined)?.name,
		meta_error_message: (error.meta?.error as Error | undefined)?.message,
		error_detail: !!error && 'detail' in error ? error.detail : undefined,
		request_url: requestUrl,
	};
};
