import { selectSessionId, selectUserId } from '../store/session';
import { useAppSelector } from './useAppSelector';

const useBackendUserIdentifier = () => {
	const userId = useAppSelector(selectUserId);
	const sessionId = useAppSelector(selectSessionId);

	if (userId) {
		return {
			type: 'user' as const,
			value: userId,
		};
	}

	if (sessionId) {
		return {
			type: 'session' as const,
			value: sessionId,
		};
	}
};

export default useBackendUserIdentifier;
